var global = {
       ip: "http://18.190.14.77:8080/", 
     // ip: "http://127.0.0.1:5000/",
    //  primary: "#2F3D44",
    //  salt: "#C82549",
    //  slc1: "#24704C",
    //  slc2: "#A5C882",
    //  color1: "#24704C",
    //  color2: "#A5C882",
    //  hashSync: "$2a$07$bIqos/./zs6LiDjGV2Ad6e",
    //  selectAll: "Selecionar Tudo",
    //  firstName: "",
    //  token: "",
    //  open: true,
   };
   
   export default global;