import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";
import global from "../app/shared/global";
import Spinner from "../app/shared/Spinner";
import localForage from "localforage";


const Hipotese = lazy(() => import("./hipoteses/Hipoteses"));
const Dashboard = lazy(() => import("./feature/Dashboard"));
const Painel = lazy(() => import("./painel/Painel"));
const Diagnostico = lazy(() => import("./diagnostico/Diagnostico"));
const Risco = lazy(() => import("./risco/Risco"));
const NewDashboard = lazy(() => import("./dashboard/NewDashboard"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));

class AppRoutes extends Component {
  constructor() {
    super();
    this.state = {
      authorized: false,
    };
  }

  checkValidToken = async () => {
    const token = await localForage.getItem("keys");
    if (token !== null) {
      await localForage.getItem("keys").then((keys) => {
        axios
          .get(global.ip + "register/getcargos", {
            headers: {
              Authorization: "Bearer " + keys.token.toString(),
            },
          })
          .then(
            () => {
              this.setState({
                authorized: true,
              });
            },
            (err) => {
              this.setState({
                authorized: false,
              });
            }
          );
      });
    }
    this.setState({
      authorized: false,
    });
    // Validation logic...
  };
  async componentDidMount() {
    this.checkValidToken();
  }

  render() {
    console.log(window.location.href.includes("/confirmpassword/"));
    return (
      <Suspense fallback={<Spinner />}>
        {window.location.href.includes("/confirmpassword/") ? (
        <Route path="/confirmpassword/:id/:code" component={ChangePassword} />
        ) : this.state.authorized ? (
          <Redirect to="/newDash" />
        ) : (
          <Redirect to="/user-pages/login-1" />
        )}
        <Switch>
          <Route exact path="/hipotese" component={Hipotese} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/painel" component={Painel} />
          <Route exact path="/diagnostico" component={Diagnostico} />
          <Route exact path="/newDash" component={NewDashboard} />
          <Route exact path="/risco" component={Risco} />
          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />
          <Route
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />
          <Route path="/tables/basic-table" component={BasicTable} />
        
          ;
          <Route path="/icons/mdi" component={Mdi} />
          <Route path="/charts/chart-js" component={ChartJs} />
          <Route path="/user-pages/login-1" component={Login} />
          <Route path="/user-pages/register-1" component={Register1} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
